import React, { useRef, useState, setState, useEffect } from 'react';
import styled from 'styled-components'
import Prismic from 'prismic-javascript'
import { Date, Link, RichText } from 'prismic-reactjs'
import {GlobalStyle} from './styles'
import ReactToPrint from 'react-to-print';
var PrintTemplate = require ('react-print');

const linkResolver = (doc) => {
// Pretty URLs for known types
if (doc.type === 'medicated') return `/${doc.uid}`
// Fallback for other types, in case new custom types get created
return `/doc/${doc.id}`
}


const Container = styled.div`
width: 4in;
height: 3in;
display: flex;

`


const PrintButton = styled.button`
margin: 0;
background: none;
border: var(--brdr);
font-family: beaufort-medium;
color: var(--swmain);
padding: 10px;
display: block;

:hover{
  cursor: pointer;
}
`

const Border = styled.div`
border: var(--brdr);
margin: .16in .2in .2in;
display: inline-block;
width: 3.6in;
height: 2.6in;
color: var(--swmain);
display: flex;
flex-direction: column;
justify-content: space-between;
align-items: center;
h2{
  text-transform: uppercase;
  font-size: 29pt;
  letter-spacing: -1px;
  text-align: center;
  margin: 0;
height: fit-content;
}
`

const Terps = styled.h4`
ul{
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;

  li{
    padding-right: 5px;
  }
  li + li{

    :before{
      content: "  ·  "

    }
  }
}
font-family: beaufort-medium;
font-weight: normal;
font-size: 11pt;
text-align: center;
margin-top: 10pt;
margin-bottom: 0;
height: fit-content;
`

const Farm = styled.h4`
font-family: transcribed;
font-size: 10pt;
font-weight: normal;
text-align: center;
display: inline-block;
margin-bottom: 20px;
span{
  width: 90%;
  height: 2px;
  padding: 0 10px 10px;
  border-bottom: var(--brdr);
}
`

const Class = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
height: 100%;
`

const Classies = styled.h4`

ul{
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
  li{
    padding-right: 5px;
  }
  li + li{

    :before{
      content: "  ·  "

    }
  }
}

font-family: beaufort-condbold;
font-weight: normal;
font-size: 12pt;
text-align: center;
margin: 0;
line-height: 12px;
`

const ClassDesc = styled.h5`
text-align: center;
font-family: beaufort-condbold;
font-weight: normal;
margin: 10px 0 0;
span{
border-bottom: var(--brdr);
padding: 0px 10px 6px;
}
`
const TextHold = styled.div`
height: fit-content;
display: flex;
flex-direction: column;
width: 100%;
`

const Cbnds = styled.ul`


list-style: none;
text-decoration: none;
display: flex;
justify-content: space-between;
flex-flow: row wrap;
align-items: center;
width: 100%;
height: .47in;
border-top: var(--brdr);
padding: 0;
margin: 16pt 0 0;

li + li{
  border-left: var(--brdr);
  padding: 3px 0;
}

li{
  font-family: transcribed;
  font-size: 12pt;
  text-align: center;
  flex: 1 1;
  line-height: 27pt;


strong{
    font-family: beaufort-condbold;
    font-size: 9pt;
    padding-right: 6pt;
    text-transform: uppercase;



  }
}
`

const Prices = styled.ul`

list-style: none;
text-decoration: none;
display: flex;
justify-content: space-between;
flex-flow: row wrap;
color: var(--swmain);
padding: 0;
width: 100%;
margin: 0;
border-top: var(--brdr);

height: .44in;
li{
  text-align: center;
  flex: 1 1;
  font-family: transcribed;
  font-size: 12pt;
  position:relative;
  padding: 10pt;

  strong{
    position:absolute;
    left: 0;
    width: 100%;
    top: -6pt;
    text-align: center;
    font-family: beaufort-condbold;
    font-size: 9pt;
    text-transform: uppercase;


    span{
      position: relative;
      background-color: #fff;

      padding: 2pt 4pt 0;
    }
  }
}
li + li{
  border-left: var(--brdr);
}
`

const Gradient = styled.div`
width: 100%;
height: .3625in;
border-top: var(--brdr);
position: relative;
overflow: hidden;
display: flex;
justify-content: space-between;
span{
  position: relative;
  font-family: beaufort-condbold;
  z-index: 2 !important;
  margin: .08in .125in;
}
`
const GradientBg = styled.img`
position: absolute;
background-repeat: no-repeat;
z-index: 1;
top: -66px;

`

const ProdType = styled.div`
display: flex;
justify-content: center;
position: absolute;
background: white;
top: 6pt;
font-family: beaufort-medium;
font-weight: normal;
padding: 0 10px;
`

const Pricee = styled.div`
position: relative;
display: flex;
flex-direction: column;
align-items: center;
top: -13px;
h3{
  position: absolute;
text-align: center;
background: white;
padding: 0 10px 6px;
font-family: beaufort-medium;
font-size: 20pt;
line-height: 12pt;
}
`

export default class NonMedicated extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      doc: null,
      notFound: false,

    }
    if (props.prismicCtx) {
      this.fetchPage(props);
    }
  }

  componentDidUpdate(prevProps) {
    this.props.prismicCtx.toolbar();
    if (!prevProps.prismicCtx) {
      this.fetchPage(this.props);
    }
  }

  fetchPage(props) {
    if (props.prismicCtx) {
      // We are using the function to get a document by its uid
      return props.prismicCtx.api.getByUID('non-medicated', props.match.params.uid, {}, (err, doc) => {
        if (doc) {
          // We put the retrieved content in the state as a doc variable
          this.setState({ doc });
        } else {
          // We changed the state to display error not found if no matched doc
          this.setState({ notFound: !doc });
        }
      });
    }
    return null;
  }



  priceBlock(){
    const SizePrice = this.state.doc.data.prices.map(function(reference, index){
      return(
        <li key={index}><strong><span>{RichText.asText(reference.size)}</span></strong>{RichText.asText(reference.price)}</li>
      )
    });
      return(
        <Prices>
        {SizePrice}
        </Prices>
      )
  }




  render(){

    if (this.state.doc) {
        return(
          <div>
          <Container>
          <GlobalStyle/>
          <Border>



          <ProdType>{RichText.asText(this.state.doc.data.product_type)}</ProdType>
          <Class>

          <Farm><span>{RichText.asText(this.state.doc.data.vendor)}</span></Farm>
          <h2>{RichText.asText(this.state.doc.data.name)}</h2>
          <Classies>{RichText.render(this.state.doc.data.description)}</Classies>
          </Class>

          <TextHold>
          {this.priceBlock()}
            </TextHold>
          </Border>
          </Container>
          <div id="react-no-print">
          <PrintButton onClick={() => window.print()}>PRINT</PrintButton>
          <a href="/"><PrintButton>Home</PrintButton></a></div>
          </div>
        );
      }else return (<h2>d'oh!</h2>)
  }

}
