import React, { useRef, useState, setState, useEffect } from 'react';
import styled from 'styled-components'
import Prismic from 'prismic-javascript'
import { Date, Link, RichText } from 'prismic-reactjs'
import {GlobalStyle} from './styles'

const linkResolver = (doc) => {
// Pretty URLs for known types
if (doc.type === 'flower_card') return `/${doc.uid}`
// Fallback for other types, in case new custom types get created
return `/doc/${doc.id}`
}

const Container = styled.div`
  display: flex;
  color: var(--swmain);
  margin: 30px;
  text-align: center;
  div{

  }
h2{
  font-size: 32px;
  font-family: beaufort-condbold;
  border-bottom: var(--brdr);
  display: inline-block;
  padding: 0 0 0 0;
  margin: 0;
  width: 100%;
}

h3{
  font-size: 20px;
  font-family: beaufort-cond;
}

a{
  text-decoration: none;
  margin: 0;
  color: var(--swmain);
}
`
const Bg = styled.div`
position: fixed;
top: 0; right: 0; left: 0; bottom: 0;
background-color: var(--swbg);
z-index: -1;
`

const CardIndex = () => {
  const apiEndpoint = 'https://somewherecards.cdn.prismic.io/api/v2'
  const client = Prismic.client(apiEndpoint)

  const [doc, setDocData] = React.useState({result: null})
  const [docMed, setDocMedData] = React.useState({resultMed: null})
  const [docNon, setDocNonData] = React.useState({resultNon: null})
  const [docsmMen, setDocsmMen] = React.useState({resultsmMen: null})

  React.useEffect(() => {
  const fetchData = async () => {

    const result = await client.query(
      Prismic.Predicates.at('document.type', 'flower_card'),
      {pageSize: 1000}
    );

    const resultMed = await client.query(
      Prismic.Predicates.at('document.type', 'medicated'),
      {pageSize: 1000}
    );

    const resultNon = await client.query(
      Prismic.Predicates.at('document.type', 'non-medicated'),
      {pageSize: 1000}
    );

    const resultsmMen = await client.query(
      Prismic.Predicates.at('document.type', 'small_menu'),
      {pageSize: 1000}
    );

    if(result) {
      setDocData({result: result.results});
    }

    if(resultMed) {
      setDocMedData({resultMed: resultMed.results})
    }

    if(resultMed) {
      setDocNonData({resultNon: resultNon.results})
    }

    if(resultsmMen) {
      setDocsmMen({resultsmMen: resultsmMen.results})
    }

  }
  fetchData();
}, []);

const Flowers = () => {
  if(doc.result){
    const result = doc.result;
    return(

          <div>
          <h2>Flower</h2>
          <GlobalStyle/>
          {result.map((card) => (
            <a href={`flower/${card.uid}`}><h3>{RichText.asText(card.data.name)}</h3></a>
          ))}</div>
    )
  }else return null;
}

const Medicated = () => {
  if(docMed.resultMed){
    const resultMed = docMed.resultMed;
    return(

          <div>
          <h2>Medicated</h2>
          <GlobalStyle/>
          {resultMed.map((card2) => (
            <a href={`medicated/${card2.uid}`}><h3>{RichText.asText(card2.data.name)}</h3></a>
          ))}</div>
    )
  }else return null;
}

const NonMed = () => {
  if(docNon.resultNon){
    const resultNon = docNon.resultNon;
    return(

          <div>
          <h2>Non-Medicated</h2>
          <GlobalStyle/>
          {resultNon.map((card3) => (
            <a href={`non-medicated/${card3.uid}`}><h3>{RichText.asText(card3.data.name)}</h3></a>
          ))}</div>
    )
  }else return null;
}

const SmMen = () => {
  if(docsmMen.resultsmMen){
    const resultsmMen = docsmMen.resultsmMen;
    return(

          <div>
          <h2>Small Menus</h2>
          <GlobalStyle/>
          {resultsmMen.map((card4) => (
            <a href={`small-menu/${card4.uid}`}><h3>{RichText.asText(card4.data.menu_name)}</h3></a>
          ))}</div>
    )
  }else return null;
}

return(
  <Container>
  <Bg/>
  <Flowers/>
  <Medicated/>
  <NonMed/>
  <SmMen/>
  </Container>
)
}

export default CardIndex;
