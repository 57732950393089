import React from 'react';
import logo from './logo.svg';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect
} from 'react-router-dom';
import FlowerCard from './pages/flowerCard.js';
import Medicated from './pages/medicated.js';
import Prismic from 'prismic-javascript';
import CardIndex from './pages/cardIndex.js';
import NonMedicated from './pages/nonMedicated.js';
import SmallMenu from './pages/smallMenu.js';

const App = (props) => (
    <Router>
    <Switch>
        <Route exact path = "/" component= {CardIndex}/>
        <Route exact path="/flower/:uid" render={routeProps => <FlowerCard {...routeProps} prismicCtx={props.prismicCtx} />} />
        <Route exact path="/medicated/:uid" render={routeProps => <Medicated {...routeProps} prismicCtx={props.prismicCtx} />} />
        <Route exact path="/small-menu/:uid" render={routeProps => <SmallMenu {...routeProps} prismicCtx={props.prismicCtx} />} />
        <Route exact path="/non-medicated/:uid" render={routeProps => <NonMedicated {...routeProps} prismicCtx={props.prismicCtx} />} />
    </Switch>
    </Router>
);

export default App;
