import React from 'react';
import styled from 'styled-components';
import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
@font-face {
    font-family: 'transcribed';
    font-style: normal;
    src: url('../fonts/TranscribedJNL.woff') format("woff");
    font-weight: normal !important;
}

@font-face {
    font-family: 'beaufort-medium';
    font-style: normal;
    src: url('../fonts/Beaufort-Medium.woff') format("woff");
}

@font-face {
    font-family: 'beaufort-condbold';
    font-style: normal;
    src: url('../fonts/Beaufort-CondensedBold.woff') format("woff");
}

@font-face {
    font-family: 'beaufort-cond';
    font-style: normal;
    src: url('../fonts/beaufort-condensed-webfont.woff') format("woff");
}

:root {
    --swbg:#FBF1E5;
    --swmain:#7C6D5E;
    --brdr: 2px solid var(--swmain);

    --tl: url('../images/swGradient_TL.png');
}

h2{
  font-family: beaufort-cond;
  font-weight: normal;
}

.full{
  width: 100%;
}
`
